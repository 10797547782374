import Image from 'next/image';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { AddToCart, AddToFavourites, Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { getImageSrcUrl } from '@components/Image/Image';
import { PortableTextBlockRenderer } from '@components/PortableText/PortableText';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useStockLevel } from '@hooks/useStockLevel';
import { useWindowSize } from '@hooks/useWindowSize';
import { HomePageCarouselItem } from '@interfaces/ICarousel';
import {
  CarouselItemReferencedProduct,
  CarouselItemReferencedProductVariant,
} from '@interfaces/Product';
import { blurUrl, calculateHeight, roundUpNearestHundred } from '@lib/images';

function ProductActions({
  product,
  productVariant,
  ctaPrimaryLink,
  ctaPrimaryLinkText,
  ctaSecondaryLink,
  ctaSecondaryLinkText,
}: {
  product: CarouselItemReferencedProduct;
  productVariant?: CarouselItemReferencedProductVariant;
  ctaPrimaryLinkText?: string;
  ctaPrimaryLink?: string;
  ctaSecondaryLinkText?: string;
  ctaSecondaryLink?: string;
}) {
  const { inStock } = useStockLevel(product.productId.toString());
  const isDigital = useRef(product.sku.includes('BN-EBOOK'));
  const variantId = productVariant
    ? productVariant.variantId
    : product.defaultVariant.variantId;

  return (
    <div className="home-cta-actions mb-5 flex flex-wrap items-end gap-4 sm:mb-0">
      {ctaPrimaryLink && (
        <Button className="home-cta-primary" href={ctaPrimaryLink}>
          {ctaPrimaryLinkText}
        </Button>
      )}
      {inStock && !isDigital.current && !ctaPrimaryLink && (
        <AddToCart
          productId={product.productId}
          variantId={variantId || 0}
          quantity={1}
        />
      )}
      {ctaSecondaryLink && (
        <Button
          className="home-cta-secondary"
          href={ctaSecondaryLink}
          buttonStyle={ButtonStyle.secondary}
        >
          {ctaSecondaryLinkText}
        </Button>
      )}
      {!ctaSecondaryLink && (
        <AddToFavourites
          sku={product.sku}
          fullWidth
          productId={product.productId}
        />
      )}
    </div>
  );
}

function ActionButtons({
  carouselItem,
}: {
  carouselItem: HomePageCarouselItem;
}) {
  const {
    referencedDocument,
    referencedProductVariant,
    ctaPrimaryLink,
    ctaPrimaryLinkText,
    ctaSecondaryLink,
    ctaSecondaryLinkText,
  } = carouselItem;

  // No referencd document, just using given links
  if (!referencedDocument)
    return (
      <div className="home-cta-actions mb-5 flex flex-wrap items-end gap-4 sm:mb-0">
        {ctaPrimaryLink && (
          <Button className="home-cta-primary" href={ctaPrimaryLink}>
            {ctaPrimaryLinkText}
          </Button>
        )}
        {ctaSecondaryLink && (
          <Button
            className="home-cta-secondary"
            href={ctaSecondaryLink}
            buttonStyle={ButtonStyle.tertiary}
          >
            {ctaSecondaryLinkText}
          </Button>
        )}
      </div>
    );

  // Document is references
  if (referencedDocument.type === 'product') {
    return (
      <ProductActions
        product={referencedDocument as CarouselItemReferencedProduct}
        productVariant={referencedProductVariant}
        ctaPrimaryLink={ctaPrimaryLink}
        ctaPrimaryLinkText={ctaPrimaryLinkText}
        ctaSecondaryLink={ctaSecondaryLink}
        ctaSecondaryLinkText={ctaSecondaryLinkText}
      />
    );
  }

  // if referencedDocument is not a product
  return (
    <div className="home-cta-actions mb-5 flex flex-wrap items-end gap-4 sm:mb-0">
      {/*
      use the slug from the referenced document for the primary link (with whatever text is specified for it),
      unless there is a URL specified in the Primary link URL field */}
      {ctaPrimaryLinkText && (
        <Button
          className="home-cta-primary"
          href={ctaPrimaryLink || referencedDocument.slug}
        >
          {ctaPrimaryLinkText}
        </Button>
      )}
      {ctaSecondaryLink && (
        <Button
          className="home-cta-secondary"
          href={ctaSecondaryLink}
          buttonStyle={ButtonStyle.secondary}
        >
          {ctaSecondaryLinkText}
        </Button>
      )}
    </div>
  );
}

// get the appropriate link for headline
const getHeadlineLink = (ci: HomePageCarouselItem) => {
  if (ci.ctaPrimaryLink) return ci.ctaPrimaryLink;
  if (ci.referencedDocument) return ci.referencedDocument.slug;
  return '';
};

export function CarouselItem({
  carouselItem,
  totalSlides,
  priority,
}: {
  carouselItem: HomePageCarouselItem;
  totalSlides: number;
  priority: boolean;
}) {
  const { width, height } = useWindowSize();
  const [image, setImage] = useState<{
    src: string;
    width: number;
    height: number;
  }>();

  useEffect(() => {
    if (!width) return;
    const usedWidth = roundUpNearestHundred(width);
    if (usedWidth <= 600) {
      // square on mobile
      setImage({
        src: getImageSrcUrl({
          image: carouselItem.image,
          width: usedWidth,
          height: usedWidth,
        }),
        width: usedWidth,
        height: usedWidth,
      });
    } else if (usedWidth <= 800) {
      // 2:1 ratio
      setImage({
        src: getImageSrcUrl({
          image: carouselItem.image,
          width: usedWidth,
          height: Math.round(0.75 * usedWidth),
        }),
        width: usedWidth,
        height: Math.round(0.75 * usedWidth),
      });
    } else if (usedWidth <= 1200) {
      // 2:1 ratio
      setImage({
        src: getImageSrcUrl({
          image: carouselItem.image,
          width: usedWidth,
          height: Math.round(0.875 * usedWidth),
        }),
        width: usedWidth,
        height: Math.round(0.875 * usedWidth),
      });
    } else {
      // Full height
      setImage({
        src: getImageSrcUrl({
          image: carouselItem.image,
          width: usedWidth,
          height: height
            ? height - 201
            : (calculateHeight(usedWidth, carouselItem.image.ratio) ??
              usedWidth),
        }),
        width: usedWidth,
        height: height
          ? height - 201
          : (calculateHeight(usedWidth, carouselItem.image.ratio) ?? usedWidth),
      });
    }
  }, [width, height, carouselItem.image]);

  return (
    <div className="relative flex h-full flex-col sm:block">
      {carouselItem.image && (
        <div className="relative mx-auto my-0 min-h-[75vw] overflow-hidden sm:min-h-[75vw] md:min-h-[87.5vw] xl:min-h-[calc(100vh_-201px)]">
          {image && (
            <Image
              src={image.src}
              width={image.width}
              height={image.height}
              quality={100}
              alt={carouselItem.image.altText}
              placeholder="blur"
              blurDataURL={blurUrl(image.width, image.height)}
              priority={priority}
              unoptimized
              layout="responsive"
              objectFit="cover"
            />
          )}
        </div>
      )}

      <div
        data-tracking-info={carouselItem.ctaName}
        className="home-carousel-item  
        relative left-0 right-0
        top-0 overflow-visible
        sm:absolute sm:top-[80px] sm:max-h-[calc(100%_-_130px)] xl:top-[120px] xl:max-h-[calc(100%_-_170px)]
        xl:overflow-hidden"
      >
        <div className="relative mx-auto my-0 flex h-full w-full max-w-maxWidthMenu gap-2.5 p-0 sm:pl-[275px]">
          <div className="z-2 h-full p-6 xl:p-12.5">
            {totalSlides > 1 && (
              <div
                className="relative mb-2.5 flex -translate-y-2/4
            translate-x-0 gap-2.5 sm:absolute sm:right-4 sm:mb-0 xl:right-4"
              >
                <ButtonBack
                  tabIndex={-1}
                  data-testid={`carousel-prev`}
                  className="carousel-navigation home-carousel-navigation"
                >
                  <FiChevronLeft size={20} />
                </ButtonBack>
                <ButtonNext
                  tabIndex={-1}
                  data-testid={`carousel-next`}
                  className="carousel-navigation home-carousel-navigation"
                  style={{
                    right: 0,
                  }}
                >
                  <FiChevronRight size={20} />
                </ButtonNext>
              </div>
            )}

            <div
              className="absolute mb-3 inline-block 
            -translate-y-2/4 translate-x-0 border-b-0 border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark pb-0.5 pl-2.5
            pr-5 pt-0.5 font-secondary uppercase tracking-wide text-white sm:text-xl xl:text-3xl"
            >
              {carouselItem.postType}
            </div>
            <TextLink
              href={getHeadlineLink(carouselItem)}
              className="home-cta-title block no-underline hover:no-underline"
            >
              <h2
                className="leading-2 mx-0 my-5 pt-5 font-primary text-4xl italic 
              tracking-tighter text-black dark:text-white sm:text-5xl xl:text-6xl"
              >
                {carouselItem.ctaHeadline}
              </h2>
            </TextLink>
            {carouselItem.ctaDescription && (
              <PortableTextBlockRenderer
                content={carouselItem.ctaDescription}
                componentsOverride={{
                  block: {
                    normal: ({ children }: { children?: ReactNode }) => (
                      <p
                        className="mx-0 mb-5 mt-2.5 text-lg text-grey-dark
                      dark:text-white xl:text-xl"
                      >
                        {children}
                      </p>
                    ),
                  },
                }}
              />
            )}
            <ActionButtons carouselItem={carouselItem} />
          </div>

          <div className="absolute z-1 hidden h-full w-full bg-white dark:bg-black sm:block sm:w-[calc(100%-275px)]" />
        </div>
      </div>
    </div>
  );
}
